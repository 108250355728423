<template>
  <router-view :key="$route.fullPath" />
</template>

<script>
export default {}
</script>


<style>
@import './css/styles.css';
@import './css/tiny-date-picker.css';
</style>