import { createWebHistory, createRouter } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../components/AppHome.vue'),
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import('../components/AppHome.vue'),
  },
  {
    path: '/faq',
    name: 'EtherID FAQ',
    component: () => import('../components/FAQ.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router
